import * as React from "react";
import dayjs from "dayjs";
import type { Dayjs } from "dayjs";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { AnnouncementHeading } from "components/AnnouncementHeading";
import { ExternalLink } from "components/ExternalLink";
import { getAnnouncementList } from "libs/announcement";
import type { AnnouncementFields } from "libs/announcement";
import AnnLoading from 'images/ann-loading.webp';
import AnnLoadingFailed from 'images/ann-loading-failed.webp';

type LoadingState = 'loading' | 'success' | 'failed';

type SimpleAnnouncement = {
    id: string;
    title: string;
    date: Dayjs;
    link: string;
};

export const HomeAnnouncements: React.FC = () => {
    const [loadingState, setLoadingState] = React.useState<LoadingState>('loading');
    const [announcements, setAnnouncements] = React.useState<SimpleAnnouncement[]>([]);
    const { t, i18n } = useTranslation();

    React.useEffect(() => {
        const loadAnnouncementFromRemote = async () => {
            setAnnouncements([]);
            try {
                const response = await fetch(getAnnouncementList(i18n.language));
                const posts: AnnouncementFields[] = await response.json();
                setAnnouncements(
                    posts.map(({ id, date_gmt, title, jetpack_shortlink }) => ({
                        id: id.toString(),
                        title: title.rendered,
                        date: dayjs(date_gmt),
                        link: jetpack_shortlink,
                    }))
                );
                setLoadingState('success');
            } catch (e: any) {
                setLoadingState('failed');
            }
        }

        if (loadingState !== 'loading') return;
        loadAnnouncementFromRemote();
    }, [loadingState]);

    return (
        <div className="max-w-wide mx-auto page-trailer">
            <AnnouncementHeading as="h2" />
            <div className="pt-7 pb-7 md:pt-28 md:pb-24 bg-gradient-100 md:bg-gradient-200 px-5 font-bold flex flex-col items-center">
                <div className="md:grid md:grid-cols-3 md:gap-14 max-w-closet mx-auto w-full">
                    {loadingState === 'loading' && (
                        <section className="mb-10 flex flex-col items-center py-60 lg:py-20 md:col-start-2">
                            <img
                                src={AnnLoading}
                                alt="announcement loading"
                                width="325"
                                height="60"
                            />
                        </section>
                    )}
                    {loadingState === 'failed' && (
                        <section className="mb-10 flex flex-col items-center py-60 lg:py-20 md:col-start-2">
                            <img
                                src={AnnLoadingFailed}
                                alt="announcement loading failed"
                                width="325"
                                height="60"
                            />
                        </section>
                    )}
                    {loadingState === 'success' && announcements.map(({ id, title, date, link }) => (
                        <section key={id} className="mb-10 flex flex-col">
                            <time className="block text-sm tracking-widest lg:text-2xl lg:tracking-wider italic mb-4 lg:mb-3">
                                {date.format('YYYY.MM.DD')}
                            </time>
                            <div className="flex flex-col grow border-y-5 lg:border-y-10 py-6 lg:py-2 mb:pb-4">
                                <h3 className="mb-6 lg:my-4 lg:pt-1 lg:text-2xl grow truncate">
                                    {title}
                                </h3>
                                <ExternalLink
                                    href={link}
                                    className="inline-block uppercase ml-auto lg:text-xl text-white italic bg-black py-1 lg:mt-4 lg:py-2 px-2.5 lg:px-5"
                                >
                                    {t("button.read_more")}-
                                </ExternalLink>
                            </div>
                        </section>
                    ))}
                </div>
            </div>
        </div>
    );
};
